import React from "react";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "./colors";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    minHeight: props.height,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      minHeight: "40vh",
    },
  }),
  img: (props) => ({
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: -10,
    width: props.imageWidth,
    height: "100%",
    objectPosition: props.objectPosition,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),

  content: (props) => ({
    width: "100%",
    height: props.height,
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      height: "40vh",
    },
  }),

  leftContent: {
    marginRight: "1vw",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    "& h1": {
      position: "relative",
      zIndex: 2,
      marginBottom: 0,
      fontSize: "calc( 2vw + 2vh)",
      lineHeight: "calc( 2vw + 2vh) + 0.1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "3rem",
        lineHeight: "3.5rem",
      },
      fontWeight: 800,

      "&:before": {
        position: "absolute",
        content: '""',
        zIndex: "-1",
        width: "0",
        top: "0",
        left: "0",
        height: "0",
        border: `1vw solid transparent`,
      },
      "&:after": {
        position: "absolute",
        content: '""',
        zIndex: "-1",
        width: "0",
        height: "0",
        border: `1vw solid transparent`,
        bottom: "0",
        right: "0",
      },
    },
    "& span": {
      width: "50%",
      alignSelf: "flex-end",
      marginTop: "-0.5vw",
      borderBottom: `1.5vw solid ${colorPalette.blue}`,
    },
  },

  a: {
    position: "absolute",
    zIndex: 1,
    content: '""',
    border: `0.5vw solid ${colorPalette.grey}`,
    width: `16vw`,
    height: `12vw`,
    marginLeft: "-12vw",
    top: "-6vw",
  },

  rightContent: {
    zIndex: 1,
    paddingLeft: "6vw",
    justifySelf: "start",
    alignSelf: "center",
  },

  box: {
    background: "#transparent",
    position: "absolute",
    right: "-5vw",
    zIndex: -4,
    content: '""',
    [theme.breakpoints.down("xs")]: {
      display: "none",
      top: 0,
    },
    width: `24vw`,
    height: `20vw`,
    "&:before": {
      position: "absolute",
      content: '""',
      zIndex: "-1",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      borderTopColor: "#ffffff",
      borderRightColor: "#ffffff",
      border: `1vw solid transparent`,
      transition: "width 0.1s ease-out, height 0.1s ease-out 0.1s",
    },
    "&:after": {
      position: "absolute",
      content: '""',
      zIndex: "-1",
      width: "100%",
      height: "100%",
      bottom: "0",
      right: "0",
      borderBottomColor: "#ffffff",
      borderLeftColor: "#ffffff",
      border: `1vw solid transparent`,
      transition: "border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s",
    },
  },
}));

const SectionTitle = ({ title, img, height, imageWidth, leftComponent, rightComponent, setRef, objectPosition }) => {
  const classes = useStyles({
    height,
    imageWidth,
    objectPosition,
  });
  return (
    <div className={classes.root} ref={(element) => setRef(element)}>
      <div className={classes.content}>
        <div className={classes.leftContent}>
          <div className={classes.box} />
          <h1 className={classes.title}>{title}</h1>
          <span />
          {leftComponent}
        </div>
        <div className={classes.rightContent}>{rightComponent}</div>
      </div>
      <img src={img} className={classes.img} alt="" />
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  objectPosition: PropTypes.string,
};

SectionTitle.defaultProps = {
  height: `50vh`,
  imageWidth: "50%",
  objectPosition: "center",
};

export default SectionTitle;
