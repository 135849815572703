import React from "react";
import { withStyles } from "@material-ui/core";
import SubPageBase from "../../components/SubPageBase";
import Typography from "@material-ui/core/Typography";
import misyonImg from "../../images/misyon.png";

const styles = (theme) => ({
  root: {
    fontSize: "1.5em",
  },
  content: {
    width: "80vw",
    margin: "auto",
    padding: "calc(3vw + 3vh)",
  },
  video: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
});

const IndexPage = ({ classes }) => (
  <SubPageBase title={"AMACIMIZ"} img={misyonImg} className={classes.root} objectPosition="top">
    <div className={classes.content}>
      <Typography variant="h3" gutterBottom>
        Amacımız; İnsanlara ve Gezegene Faydalı Olmak.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Misyonumuz; sporun keyif ve faydalarını sürdürülebilir şekilde büyük çoğunluğa ulaşılabilir kılmak!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Sporun hayatımızın bir parçası olduğunu ve bir lüks olmadığını düşünüyoruz. Spor deneyimlerine yardımcı olmak,
        ilham vermek ve rehberlik etmek istiyoruz. Her gün aktif olmanın ve yeni sporlar keşfetmenin sağlıklı bir yaşam
        tarzının önemli bir parçası olduğuna inanıyoruz.
      </Typography>
      <br />
      <Typography variant="subtitle1" gutterBottom>
        Bu nedenle; en düşük fiyatta ilgi çeken, yenilikçi ve teknik ürünler sağlamak için çalışıyoruz.
      </Typography>
      <br />
      <br />
      <div className={classes.video}>
        <iframe
          title="Amacımız"
          width="600"
          height="400"
          src="https://www.youtube-nocookie.com/embed/rySm3xQfN6s?rel=0&modestbranding=1&iv_load_policy=3"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
