import React, { useEffect, useRef } from "react";
import Layout from "./layout";
import SEO from "./seo";
import PropTypes from "prop-types";
import SectionTitle from "./SectionTitle";
import { AttrPlugin, CSSPlugin, Power4, TimelineMax } from "gsap";
import img from "../images/dc-magaza.png";

const SubPageBase = ({ objectPosition, title, img, leftComponent, children, className = undefined }) => {
  let titleRef = useRef();
  let contentRef = useRef();

  const tl = new TimelineMax();
  //do not remove this
  // noinspection JSUnusedLocalSymbols
  const plugins = [CSSPlugin, AttrPlugin];

  useEffect(() => {
    tl.fromTo(titleRef.current, 1, { y: -100, opacity: 0 }, { y: 0, opacity: 1, ease: Power4.easeInOut }).fromTo(
      contentRef.current,
      0.5,
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, ease: Power4.easeInOut }
    );
  }, []);
  return (
    <Layout>
      <SEO title={title} />
      <SectionTitle
        title={title}
        leftComponent={leftComponent}
        img={img}
        setRef={(element) => (titleRef.current = element)}
        objectPosition={objectPosition}
      />
      <div ref={contentRef} className={className}>
        {children}
      </div>
    </Layout>
  );
};

SubPageBase.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  leftComponent: PropTypes.node,
  objectPosition: PropTypes.string,
};

SubPageBase.defaultProps = {
  title: "",
  img: img,
  leftComponent: undefined,
  objectPosition: "unset",
};

export default SubPageBase;
